import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, useField } from 'react-final-form';
import { Select as MuiSelect } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Label from './Label';
import { compose, required } from '../utils/form/validators';

const useStyles = makeStyles(theme => ({
  muiFormControl: {
    minWidth: '10rem',
    '& .MuiInputLabel-formControl': {
      position: 'initial'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.color.border.main
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: '.525em',
      paddingBottom: '.525em',
      paddingLeft: '.75em',
      color: props =>
        props.disabled ? theme.color.text.light : theme.color.text.main,
      fontSize: theme.font.size.input
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.color.primary.main
    },
    ...(theme.components?.textField?.root
      ? theme.components?.textField?.root
      : {})
  },
  muiSelectMenu: {
    '& .MuiPopover-paper': {
      boxShadow: 'none',
      border: `1px solid ${theme.color.border.main}`,
      borderTop: 'transparent',
      borderRadius: '0 0 4px 4px',
      '&:hover': {
        borderColor: theme.color.border.dark
      }
    },
    '& li': {
      padding: '.5em .5em',
      minHeight: 'auto',
      lineHeight: '1.1em',
      fontSize: theme.font.size.input,
      '&[aria-selected="true"]': {
        background: `${theme.color.background.grey} !important`,
        '&:hover': {
          background: theme.color.background.grey
        }
      }
    }
  }
}));

export default function Select(props) {
  const classes = useStyles(props);
  const field = useField(props.id);

  function handleChange(v, e) {
    field.input.onChange(v);

    if (props.onChange) props.onChange(v, e);
  }

  function handleBlur() {
    field.input.onBlur();

    if (props.onBlur) props.onBlur();
  }

  function handleFocus() {
    field.input.onFocus();

    if (props.onFocus) props.onFocus();
  }

  function getValidators() {
    const validators = [];

    if (props.required) validators.push(required);

    return validators;
  }

  return (
    <Field name={props.id} validate={compose(getValidators())}>
      {({ meta, input }) => {
        const error =
          props.error || ((meta.error || meta.submitError) && meta.touched);
        const errorText =
          props.errorText ||
          (meta.touched ? meta.error || meta.submitError : '');

        return (
          <FormControl
            error={error}
            disabled={props.disabled}
            fullWidth={props.fullWidth}
            variant="outlined"
            className={classes.muiFormControl}
          >
            <Label
              for={`${props.id}-input`}
              disabled={props.disabled}
              required={props.required && !props.requiredWithoutAsterisk}
              popover={{
                title: props.popoverTitle,
                text: props.popoverText
              }}
              {...props.labelProps}
            >
              {props.label}
            </Label>
            <MuiSelect
              id={props.id}
              labelId={props.id}
              value={props.value || input.value}
              defaultValue={props.defaultValue}
              className={classes.muiSelect}
              MenuProps={{
                className: classes.muiSelectMenu,
                disableScrollLock: true
                // anchorOrigin: {
                //   vertical: 'bottom',
                //   horizontal: 'left'
                // }
                // getContentAnchorEl: null
              }}
              inputProps={{
                id: `${props.id}-input`
              }}
              onChange={e => handleChange(e.target.value, e)}
              onFocus={handleFocus}
              onBlur={handleBlur}
              sx={theme => ({
                '& .MuiSelect-select .notranslate::after': {
                  content: `"${props.placeholder || 'Bitte wählen'}"`,
                  color: theme.color.text.light
                }
              })}
            >
              {!props.required ? (
                <Select.Item value={null}>
                  {props.placeholder || 'Bitte wählen'}
                </Select.Item>
              ) : null}
              {props.children}
            </MuiSelect>
            {(errorText || props.helperText) && (
              <FormHelperText
                style={{ margin: 0 }}
                aria-label={errorText || props.helperText}
              >
                {errorText || props.helperText}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    </Field>
  );
}

Select.propTypes = {
  children: PropTypes.node.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
  labelProps: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  requiredWithoutAsterisk: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Select.defaultProps = {
  defaultValue: '',
  disabled: false,
  error: false,
  errorText: undefined,
  fullWidth: false,
  helperText: undefined,
  label: undefined,
  labelProps: {},
  onChange: () => {},
  placeholder: undefined,
  required: false,
  requiredWithoutAsterisk: false,
  value: undefined
};

Select.Item = MenuItem;
