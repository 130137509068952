import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, useField } from 'react-final-form';
import { makeStyles } from '@mui/styles';

import NumberField from './NumberField';
import {
  compose,
  maxTime,
  minTime,
  required,
  time
} from '../utils/form/validators';

// TBD: intl
const PLACEHOLDER_HOUR = 'S';
const PLACEHOLDER_MINUTE = 'M';

const useStyles = makeStyles(theme => ({
  timePicker: {
    width: '5em',
    '& .MuiInputLabel-root': {
      overflow: 'visible'
    },
    ...(theme.components?.timePicker?.root
      ? theme.components?.timePicker?.root
      : {})
  }
}));

function normalizeValue(value) {
  return value ? value.replace(':', '') : value;
}

export default function TimePicker(props) {
  const classes = useStyles(props);
  const field = useField(props.id);

  function handleChange(v, e) {
    field.input.onChange(v);

    if (props.onChange) props.onChange(v, e);
  }

  function handleBlur(e) {
    const { value } = e?.target;

    if (value !== '') {
      let updatedValue = value.replace(':', '');

      if (
        updatedValue.includes(PLACEHOLDER_HOUR) ||
        updatedValue.includes(PLACEHOLDER_MINUTE)
      ) {
        const hoursZeroed = updatedValue.replace(/S/g, '0');
        const minutesZerod = hoursZeroed.replace(/M/g, '0');
        updatedValue = minutesZerod;
      }

      if (Number(updatedValue) >= 2400) {
        updatedValue = '2359';
      }

      setTimeout(() => {
        field.input.onChange(updatedValue);
      }, 100);
    }

    field.input.onBlur();

    if (props.onBlur) props.onBlur(e);
  }

  function handleFocus() {
    field.input.onFocus();

    if (props.onFocus) props.onFocus();
  }

  function getValidators() {
    const validators = [time];

    if (props.required) validators.push(required);
    if (props.min) validators.push(minTime(props.min));
    if (props.max) validators.push(maxTime(props.max));

    return validators;
  }

  return (
    <Field name={props.id} validate={compose(getValidators())}>
      {({ meta, input }) => {
        const error =
          props.error || ((meta.error || meta.submitError) && meta.touched);
        const errorText =
          props.errorText ||
          (meta.touched ? meta.error || meta.submitError : '');

        return (
          <Box className={classes.timePicker}>
            <NumberField
              formatProps={{
                format: '##:##',
                // TBD: intl
                mask: [
                  PLACEHOLDER_HOUR,
                  PLACEHOLDER_HOUR,
                  PLACEHOLDER_MINUTE,
                  PLACEHOLDER_MINUTE
                ]
              }}
              // TBD: intl
              placeholder={`${PLACEHOLDER_HOUR}${PLACEHOLDER_HOUR}:${PLACEHOLDER_MINUTE}${PLACEHOLDER_MINUTE}`}
              endIcon="clock"
              value={normalizeValue(props.value)}
              disabled={props.disabled}
              required={props.required}
              validateOnBlur
              onFocus={handleFocus}
              onChange={handleChange}
              onBlur={handleBlur}
              error={error}
              errorText={errorText}
              helperText={props.helperText}
              FormHelperTextProps={{
                'aria-label': errorText || props.helperText
              }}
              defaultValue={normalizeValue(props.defaultValue)}
              fullWidth
              mode="string"
              popoverText={props.popoverText}
              popoverTitle={props.popoverTitle}
              autoComplete="off"
              id={props.id}
              label={props.label}
            />
          </Box>
        );
      }}
    </Field>
  );
}

TimePicker.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  error: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  min: PropTypes.string,
  max: PropTypes.string,
  required: PropTypes.bool,
  requiredWithoutAsterisk: PropTypes.bool,
  defaultValue: PropTypes.string,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string
};

TimePicker.defaultProps = {
  disabled: false,
  error: false,
  label: undefined,
  min: undefined,
  max: undefined,
  required: false,
  requiredWithoutAsterisk: false,
  defaultValue: undefined,
  errorText: undefined,
  helperText: undefined,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  value: undefined
};
