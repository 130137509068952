import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, useField } from 'react-final-form';
import { makeStyles } from '@mui/styles';

import CurrencyTextField from './CurrencyTextField';
import Label from './Label';

const useStyles = makeStyles(theme => ({
  moneyInput: {
    width: '7em',
    ...(theme.components?.moneyInput?.root
      ? theme.components?.moneyInput?.root
      : {})
  }
}));

export default function MoneyInput(props) {
  const classes = useStyles(props);
  const field = useField(props.id);

  function handleChange(v, e) {
    field.input.onChange(v);

    // Remove (leading) dash(es) (-).
    let value = v.toString().split('-').join('');

    if (value.toString().includes(',')) {
      value = `${value},00`;
    }

    if (props.onChange) props.onChange(value, { ...e, value });
  }

  function handleBlur() {
    field.input.onBlur();

    if (props.onBlur) props.onBlur();
  }

  function handleFocus() {
    field.input.onFocus();

    if (props.onFocus) props.onFocus();
  }

  return (
    <Field name={props.id}>
      {({ meta }) => {
        const error =
          props.error || ((meta.error || meta.submitError) && meta.touched);
        const errorText =
          props.errorText ||
          (meta.touched ? meta.error || meta.submitError : '');

        return (
          <Box className={classes.moneyInput}>
            <Label
              for={props.id}
              disabled={props.disabled}
              required={props.required && !props.requiredWithoutAsterisk}
              popover={{
                title: props.popoverTitle,
                text: props.popoverText
              }}
            >
              {props.label}
            </Label>
            <CurrencyTextField
              autoComplete="off"
              currencySymbol="€"
              decimalCharacter=","
              defaultValue={props.defaultValue}
              digitGroupSeparator="."
              disabled={props.disabled}
              error={error}
              errorText={errorText}
              fullWidth
              helperText={props.errorText || props.helperText}
              FormHelperTextProps={{
                'aria-label': props.errorText || props.helperText
              }}
              id={props.id}
              maximumValue={props.maximumValue}
              minimumValue={props.minimumValue || '0'}
              mode="string"
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              outputFormat="string"
              required={
                props.required &&
                !props.popoverText &&
                !props.requiredWithoutAsterisk
              }
              value={props.value}
            />
          </Box>
        );
      }}
    </Field>
  );
}

MoneyInput.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
  maximumValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minimumValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  requiredWithoutAsterisk: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

MoneyInput.defaultProps = {
  defaultValue: '0',
  disabled: false,
  error: false,
  errorText: undefined,
  helperText: undefined,
  label: undefined,
  maximumValue: '9999999999',
  minimumValue: '0',
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  required: false,
  requiredWithoutAsterisk: false,
  value: undefined
};
