import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Field, useField } from 'react-final-form';

import ElementPickerElement from './ElementPickerElement';
import Label from './Label';
import {
  compose,
  maxElements,
  minElements,
  required
} from '../utils/form/validators';

const ElementPickerContext = React.createContext({});

const ElementPickerInput = React.forwardRef(
  function ElementPickerInput(props, ref) {
    const [indices, setIndices] = useState(props.value);

    // https://material-ui.com/components/text-fields/#integration-with-3rd-party-input-libraries
    useImperativeHandle(props.inputRef, () => ({
      focus: () => {}
    }));

    useEffect(() => {
      setIndices(props.value);
    }, [props.value]);

    function addIndex(item) {
      let updatedIndices;

      if (props.mode === 'toggle') {
        updatedIndices = item;
      } else {
        updatedIndices = (indices || []).concat(item);
      }

      setIndices(updatedIndices);
      props.update(updatedIndices);
    }

    function removeIndex(item) {
      if (props.mode === 'multi') {
        const filteredIndices = indices.filter(i => item !== i);
        const updatedIndices = filteredIndices?.length
          ? filteredIndices
          : undefined;

        setIndices(updatedIndices);
        props.update(updatedIndices);
      }
    }

    return (
      <ElementPickerContext.Provider
        value={{
          mode: props.mode,
          indices,
          addIndex,
          removeIndex,
          disabled: props.disabled
        }}
      >
        <Box
          ref={ref}
          display="flex"
          flexWrap="wrap"
          id={props.id}
          width={1}
          gap={1}
        >
          {props.children}
        </Box>
      </ElementPickerContext.Provider>
    );
  }
);

export default function ElementPicker(props) {
  const field = useField(props.id);
  const [value, setValue] = useState(props.value);

  function update(indices) {
    field.input.onFocus();
    field.input.onChange(indices);
    field.input.onBlur();

    if (props.onChange) props.onChange(indices);
  }

  function getValidators() {
    const validators = [];

    if (props.required) validators.push(required);
    if (props.min) validators.push(minElements(props.min));
    if (props.max) validators.push(maxElements(props.max));

    return validators;
  }

  useEffect(() => {
    if (props.value !== value) setValue(props.value);
  }, [props.value]);

  return (
    <Field name={props.id} validate={compose(getValidators())}>
      {({ meta, input }) => {
        const error =
          props.error || ((meta.error || meta.submitError) && meta.touched);
        const errorText =
          props.errorText ||
          (meta.touched ? meta.error || meta.submitError : '');
        const internalValue = input.value === '' ? undefined : input.value;

        return (
          <FormControl fullWidth error={error} disabled={props.disabled}>
            <Label
              for={`${props.id}-input`}
              disabled={props.disabled}
              required={props.required && !props.requiredWithoutAsterisk}
              popover={{
                title: props.popoverTitle,
                text: props.popoverText
              }}
            >
              {props.label}
            </Label>
            <Input
              id={props.id}
              type="radio"
              fullWidth
              value={internalValue}
              disableUnderline
              inputProps={{
                min: props.min,
                max: props.max,
                children: props.children,
                value: internalValue,
                mode: props.mode,
                onChange: props.onChange,
                'aria-label': props.ariaLabel,
                update
              }}
              aria-describedby={`${props.id}-helper-text`}
              inputComponent={ElementPickerInput}
            />
            <input
              id={`${props.id}-input`}
              style={{ display: 'none' }}
              aria-hidden="true"
              tabIndex="-1"
              defaultValue={internalValue}
            />
            {(errorText || props.helperText) && (
              <FormHelperText
                id={`${props.id}-helper-text`}
                aria-label={errorText || props.helperText}
                style={{ margin: 0 }}
              >
                {errorText || props.helperText}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    </Field>
  );
}

ElementPicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  requiredWithoutAsterisk: PropTypes.bool,
  mode: PropTypes.oneOf(['toggle', 'multi']),
  children: PropTypes.node.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    PropTypes.string,
    PropTypes.number
  ]),
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    PropTypes.string,
    PropTypes.number
  ]),
  max: PropTypes.number,
  min: PropTypes.number,
  ariaLabel: PropTypes.string
};

ElementPicker.defaultProps = {
  max: undefined,
  label: undefined,
  error: false,
  errorText: undefined,
  value: undefined,
  mode: 'multi',
  required: false,
  requiredWithoutAsterisk: false,
  defaultValue: undefined,
  disabled: false,
  helperText: undefined,
  min: undefined,
  onChange: () => {},
  ariaLabel: ''
};

ElementPicker.Context = ElementPickerContext;
ElementPicker.Item = ElementPickerElement;
