import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Box } from '@mui/material';
import {
  DurationPicker as MuiDurationPicker,
  timeToDuration
} from '@groundkeeper/material-duration-picker';
import { Field, useField } from 'react-final-form';
import { enUS } from 'date-fns/locale';
import { formatDuration } from 'date-fns';
import { useTheme } from '@emotion/react';
import { makeStyles } from '@mui/styles';

import Label from './Label';
import { compose, required } from '../utils/form/validators';

const useStyles = makeStyles(theme => ({
  durationPickerDialog: {
    '& .MuiToolbar-root .MuiTypography-h4': {
      fontSize: '1.2em'
    }
  }
}));

const DurationPicker = React.forwardRef(function DurationPicker(props, ref) {
  const field = useField(props.id);
  const [value, setValue] = useState(field.input?.value || props.value);
  const classes = useStyles();
  const theme = useTheme();

  function handleChange(v) {
    setValue(v);
    field.input.onChange(v || null);
  }

  function getValidators() {
    let validators = [];

    if (props.required) validators.push(required);
    if (props.validators?.length) validators.push(...props.validators);

    return validators;
  }

  return (
    <Field
      name={props.id}
      initialValue={value}
      validate={compose(getValidators())}
    >
      {({ meta }) => {
        const error = (meta.error || meta.submitError) && meta.touched;
        const errorText = meta.touched ? meta.error || meta.submitError : '';

        return (
          <Box flexGrow={1}>
            <Label
              for={props.id}
              disabled={props.disabled}
              required={props.required && !props.requiredWithoutAsterisk}
              popover={{
                title: props.popoverTitle,
                text: props.popoverText
              }}
            >
              {props.label}
            </Label>
            <MuiDurationPicker
              id={props.id}
              placeholder={props.placeholder}
              views={props.views || ['days', 'hours', 'minutes']}
              error={error}
              helperText={errorText}
              FormHelperTextProps={{
                'aria-label': errorText
              }}
              title={props.title || props.label}
              value={value}
              disabled={props.disabled}
              onValueChange={e => handleChange(e, e)}
              formatDuration={duration =>
                formatDuration(duration, {
                  locale: props.locale || enUS
                })
              }
              DurationDialogProps={{
                className: classes.durationPickerDialog,
                labels: props.labels,
                title: props.title || props.label,
                DurationFieldsContainerProps: {
                  labels: props.labels,
                  dialogBackgroundColor: theme.color.primary.main
                }
              }}
            />
          </Box>
        );
      }}
    </Field>
  );
});

DurationPicker.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.node,
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
  labels: PropTypes.shape({
    cancel: PropTypes.string,
    ok: PropTypes.string,
    weeks: PropTypes.string,
    days: PropTypes.string,
    hours: PropTypes.string,
    minutes: PropTypes.string,
    seconds: PropTypes.string
  }),
  locale: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  popoverText: PropTypes.string,
  popoverTitle: PropTypes.string,
  required: PropTypes.bool,
  requiredWithoutAsterisk: PropTypes.bool,
  validators: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.string,
  views: PropTypes.arrayOf(PropTypes.string)
};

DurationPicker.defaultProps = {
  disabled: false,
  error: false,
  errorText: undefined,
  label: undefined,
  labels: undefined,
  locale: undefined,
  onChange: undefined,
  placeholder: undefined,
  popoverText: undefined,
  popoverTitle: undefined,
  required: false,
  requiredWithoutAsterisk: false,
  validators: undefined,
  value: undefined,
  views: undefined
};

export { formatDuration, timeToDuration };

export default DurationPicker;
